<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
      <v-row>
        <v-col v-if="!isEdit" col="12" sm="6">
          <v-select
            v-model="selectedSection"
            :items="sections"
            item-text="label"
            item-value="name"
            label="Выберите раздел"
            @change="selectSection"
            return-object
            :rules="[(v) => !!v || this.$t('Field is required')]"
          ></v-select>
        </v-col>
        <v-col col="12" sm="6">
          <v-select
            v-model="selectedCategory"
            :items="categories"
            item-text="title"
            item-value="id"
            label="Выберите категорию"
            return-object
            :rules="[(v) => !!v || this.$t('Field is required')]"
          ></v-select>

          <v-select
            v-model="selectedSubcategory"
            :items="subItems"
            item-text="title"
            item-value="id"
            label="Выберите подкатегорию"
            chips
            multiple
          ></v-select>
        </v-col>
        <v-col col="12" sm="6">
          <v-text-field
            v-model="title"
            label="Называние"
            :rules="[(v) => !!v || this.$t('Field is required')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12" sm="12">
          <v-btn color="green" @click="addElement"
            ><v-icon>mdi-plus</v-icon> Добавить элемент</v-btn
          >
        </v-col>
        <v-col
          v-for="(element, index) in elements"
          :key="index"
          col="12"
          sm="3"
        >
          <v-text-field
            sm="4"
            @input="setElementValue($event, index)"
            :value="element.value"
            label="Элемент"
            :rules="[(v) => !!v || this.$t('Field is required')]"
          ></v-text-field>
        </v-col>
        <v-col v-if="elements.length" col="12" sm="3">
          <v-btn color="red" @click="removeElement"
            ><v-icon>mdi-delete</v-icon>Удалить элемент</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-btn color="primary" @click="submit"
          ><v-icon>mdi-save</v-icon>Сохранить</v-btn
        >
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "CreateFilterFrom",
  props: { item: { type: Object, default: null } },
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarText: "",
      sections: [
        {
          name: "special",
          label: "Спецтехника",
        },
        {
          name: "material",
          label: "Материалы",
        },
      ],
      categories: null,
      selectedSection: null,
      selectedCategory: null,
      selectedSubcategory: [14],
      elements: [],
      title: "",
    };
  },
  computed: {
    subItems() {
      return this.selectedCategory?.subcategories || [];
    },
    isEdit() {
      return !!this.item;
    },
  },

  watch: {
    elements: {
      handler() {
        console.log(this.elements);
      },
    },
  },
  created() {
    if (this.isEdit) {
      this.selectedSection = this.sections.find(
        (s) => s.name === this.item.section
      );

      this.elements = this.item.elements;
      console.log(this.elements);
      this.title = this.item.title;
      this.getAllCategories().then((categories) => {
        this.selectedCategory = categories.find(
          (c) => c.id === this.item.category_id
        );
        this.selectedSubcategory = this.item?.ids;
      });
    }
  },
  methods: {
    selectSection() {
      this.getAllCategories();
    },
    getAllCategories() {
      let resource = this.selectedSection.name;

      if (resource === "special") {
        resource = "special-equipment";
      }

      return new Promise((resolve) => {
        this.$admin.http.get(`${resource}/categories`).then((response) => {
          this.categories = response.data.data.categories;
          resolve(this.categories);
        });
      });
    },

    addElement() {
      this.elements.push({ value: "" });
    },
    removeElement() {
      console.log(this.elements[this.elements.length - 1]);
      this.$admin.http
        .delete(
          `/filters/element/${this.elements[this.elements.length - 1].id}`
        )
        .then(() => {
          this.elements.pop();
        });
    },
    setElementValue(el, index) {
      this.elements[index].value = el;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const body = this.normalizeForBack();
        console.log(body);
        if (!this.isEdit) {
          this.$admin.http
            .post(`/filters`, body)
            .then(() => {
              this.$emit("saved");
              this.$refs.form.reset();
            })
            .catch((e) => {
              this.snackbar = true;
              this.snackbarText = e;
            });
          return;
        }
        this.$admin.http
          .put(`/filters/${this.item.id}`, body)
          .then(() => {
            this.snackbarText = "Фильтр изменен";
            this.snackbar = true;
            this.$emit("saved");
          })
          .catch((e) => {
            this.snackbar = true;
            this.snackbarText = e;
          });
      }
    },
    normalizeForBack() {
      const elements = this.elements;
      return {
        section: this.selectedSection.name,
        title: this.title,
        ids: this.selectedSubcategory,
        elements: elements,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
